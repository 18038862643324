import type { CountryCollection, Query } from '@vayapin/cs-types-public'

import { useQuery } from '@apollo/client'
import { useQueryError } from 'lib/hooks'
import { useMemo } from 'react'
import query from './query'
import { Country } from './Types'

function useBackendData(): { loading: boolean; countries: Country[] } {
  // query
  const {
    data,
    error,
    loading,
  } = useQuery<Query, CountryCollection>(query)

  useQueryError(error)

  // prepare data
  const countries = useMemo(() => {
    const nodes = data?.countries?.nodes ?? []
    //@ts-ignore
    return nodes.slice().sort((a, b) => a.name.localeCompare(b.name))
  }, [data])

  // Render component
  return {
    loading,
    countries,
  }
}

export default useBackendData
