'use client'

import type {
  CountrySelectionModalProps,
  DataModalProps,
  ModalProps,
} from './Types'
import AtomModal from 'atoms/Modal'
import useBackendData from './useBackendData'
import CurrentLocationButton from './components/CurrentLocationButton'
import List from './components/List'
import SearchInput from './components/SearchInput'
import Styles from './CountrySelectionModal.module.sass'
import useCountrySelect from './useCountrySelect'
import useJsonData from './useJsonData'

function CountrySelectionModal({
  dataSourceType,
  ...props
}: CountrySelectionModalProps) {
  if (dataSourceType === 'countryJson') {
    return <JsonDataModal  {...props} />
  }

  return <BackendDataModal {...props} />
}

function JsonDataModal(props: DataModalProps) {
  const { countries } = useJsonData()

  return <Modal countries={countries} { ...props } />
}

function BackendDataModal(props: DataModalProps) {
  const { countries } = useBackendData()

  return <Modal countries={countries} { ...props } />
}

function Modal({
  onCancel,
  onClickItem,
  title = 'Select Country',
  countries,
}: ModalProps) {
  const {
    searchTerm,
    setSearchTerm,
    onCurrentLocation,
  } = useCountrySelect(countries, onClickItem)

  return (
    <AtomModal
      headerTitle={title}
      showHeader
      isOpen={true}
      className={Styles.container}
      contentClassName={Styles.content}
      onClose={onCancel}
    >
      <CurrentLocationButton onCurrentLocation={onCurrentLocation} />
      <SearchInput setSearchTerm={setSearchTerm} />
      <div className={Styles.listWrapper}>
        <List
          countries={countries}
          searchTerm={searchTerm}
          onClickItem={onClickItem}
        />
      </div>
    </AtomModal>
  )
}

export default CountrySelectionModal
