import type { ListChildComponentProps } from 'react-window'

import { useCallback, useMemo } from 'react'
import { BsChevronRight } from 'react-icons/bs'
import Flag from 'atoms/Flag'
import Styles from './ListItem.module.sass'
import { Country } from '../Types'

export type ListItemData = {
  countries: Country[];
  onClickCountry: (country: Country) => void;
}
export type ListItemProps = ListChildComponentProps<ListItemData>

function ListItem({
  index,
  data: { countries, onClickCountry },
  style,
}: ListItemProps) {
  //
  // Pick country
  const country = useMemo(
    () => countries[index],
    [countries, index]
  )

  //
  // handle click
  const onClick = useCallback(() => {
    onClickCountry(country)
  }, [country, onClickCountry])

  //
  // Render
  return (
    <button onClick={onClick} className={Styles.button} style={style}>
      <span className='flag'>
        <Flag country={country?.iso31661 ?? ''} />
      </span>
      <span className='content'>
        {country?.name}
      </span>
      { country?.dial &&
        <span className="dialPrefix">
          +{ country?.dial }
        </span>
      }
      <span className='chevron'>
        <BsChevronRight />
      </span>
    </button>
  )
}

export default ListItem
