import { MdLocationPin } from 'react-icons/md'
import { useTranslation } from 'i18n/client'
import Button from 'atoms/Button'
import useCountrySelect from '../useCountrySelect'
import Styles from './CurrentLocationButton.module.sass'

type UseDataReturnValue = ReturnType<typeof useCountrySelect>

function CurrentLocationButton({
  onCurrentLocation,
}: { onCurrentLocation: UseDataReturnValue['onCurrentLocation'] }) {
  const { t } = useTranslation()

  return (
    <Button
      block
      className={Styles.locationBtn}
      onClick={onCurrentLocation}
    >
      <MdLocationPin/>&nbsp;
      {t('modal.countrySelector.useCurrentLocation', 'Use current location')}
    </Button>
  )
}

export default CurrentLocationButton
