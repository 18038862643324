import { useCallback, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import useCountrySelectData from '../useCountrySelect'
import ListItem, { ListItemData } from './ListItem'
import { Country } from '../Types'

type UseDataReturnValue = ReturnType<typeof useCountrySelectData>
type ListProps = Pick<UseDataReturnValue, 'countries' | 'searchTerm'>

function List({
  countries,
  searchTerm,
  onClickItem,
}: ListProps & { onClickItem: (country: Country) => void; }) {

  //
  // Filter entries
  const filteredCountries: Country[] = useMemo(() => {
    const escapedSearchTerm = searchTerm.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
    const regexp = RegExp(`.*${escapedSearchTerm}.*`, 'i')

    return countries.filter((country) => {
      const { dial } = country
      const countryTerms = [country.name]

      if (dial) {
        countryTerms.push(`+${dial}`)
        countryTerms.push(`00${dial}`)
      }

      return regexp.test(countryTerms.join(' '))
    })
  }, [searchTerm, countries])

  //
  // Count
  const count = useMemo(
    () => filteredCountries.length,
    [filteredCountries.length]
  )

  //
  // Click country
  const onClickCountry = useCallback((country: Country) => {
    onClickItem(country)
  }, [onClickItem])

  //
  // Data
  const itemData = useMemo<ListItemData>(() => ({
    countries: filteredCountries,
    onClickCountry,
  }), [filteredCountries, onClickCountry])

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          width={width}
          itemCount={count}
          itemSize={56}
          itemData={itemData}
        >
          {ListItem}
        </FixedSizeList>
      )}
    </AutoSizer>
  )
}

export default List
