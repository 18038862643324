import { gql } from '@apollo/client'

const queryReverseGeocode = gql`
  query geocodeReverseResults(
    $lat: Float!,
    $lng: Float!
  ) {
    geocodeReverseResults(latitude: $lat, longitude: $lng) {
      nodes {
        id
        countryCode
      }
    }
}
`

export default queryReverseGeocode
