import type { Query } from '@vayapin/cs-types-public'

import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'i18n/client'
import { useLazyCurrentPosition, useQueryError } from 'lib/hooks'
import { isValidNumber } from 'lib/utils'
import { debounce, first } from 'lodash'
import isObject from 'lodash/isObject'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useToast } from 'services/Toast'
import queryReverseGeocode from './queryReverseGeocode'
import { Country } from './Types'

export interface LatLngObj {
  lat: number;
  lng: number;
}

function isValidLatLngObj(
  value?: LatLngObj | null
): value is LatLngObj {
  if (!isObject(value)) return false
  if (!isValidNumber((value)?.lat)) return false
  if (!isValidNumber((value)?.lng)) return false

  return true
}

function useCountrySelect(countries: Country[], onClickItem: (item: Country) => void) {
  const { locate } = useLazyCurrentPosition()
  const toast = useToast()
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  const [latLng, setLatLng] = useState<LatLngObj>({
    lat: 0.0,
    lng: 0.0
  })

  const [reverseGeocode, {
    data: queryReverseGeocodeData,
    loading: queryReverseGeocodeLoading,
    error: queryReverseGeocodeError,
  }] = useLazyQuery<Query>(queryReverseGeocode)

  useQueryError(queryReverseGeocodeError)
  //
  // get current coordinates
  const runLocate = useCallback(async () => {
    const pos = await locate()

    if (!pos) {
      toast({
        content: t('modal.countrySelector.deniedGeolocation',
          'User denied geolocation, please allow the browser to use your actual location'),
        type: 'error',
      })
      return
    }

    if (!isValidNumber(pos?.coords?.latitude)) return
    if (!isValidNumber(pos?.coords?.longitude)) return

    setLatLng(() => ({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    }))
  }, [locate, toast, t])

  //
  // Debounced avaiability check
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedReverseGeocode = useCallback(debounce(
    (value: LatLngObj) => {
      if (!isValidLatLngObj(value)) return

      void reverseGeocode({ variables: { ...value } })
    },
    350,
  ), [])

  const onCurrentLocation = useCallback(() => {
    void runLocate()
  }, [runLocate])

  useEffect(
    () => debouncedReverseGeocode(latLng),
    [debouncedReverseGeocode, latLng]
  )

  //
  // Set Current Country
  useEffect(() => {
    const calcCountryCode = first(
      queryReverseGeocodeData?.geocodeReverseResults?.nodes
    )?.countryCode
    if (!calcCountryCode) return

    const country = countries.find((item) => item.iso31661 === calcCountryCode)
    if (country) {
      onClickItem(country)
    }

  }, [
    latLng,
    queryReverseGeocodeData?.geocodeReverseResults?.nodes,
    countries,
    onClickItem,
  ])

  // Render component
  return useMemo(() => ({
    loading: queryReverseGeocodeLoading,
    countries,
    searchTerm,
    setSearchTerm,
    onCurrentLocation,
  }), [
    countries,
    queryReverseGeocodeLoading,
    searchTerm,
    setSearchTerm,
    onCurrentLocation,
  ])
}

export default useCountrySelect
