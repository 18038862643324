import { useMemo } from 'react'
import { Country } from './Types'
import countriesData from '~/util/countries.json'

export interface LatLngObj {
  lat: number;
  lng: number;
}

function useJsonData(): { countries: Country[] } {
  const countries = useMemo(() => (
    countriesData.countries.data
      .map((country) => ({
        defaultIso6391: country.default_iso_639_1,
        defaultLatitude: parseFloat(country.default_latitude),
        defaultLongitude: parseFloat(country.default_longitude),
        defaultMapZoom: country.default_map_zoom,
        iso31661: country.iso_3166_1,
        name: country.name,
        dial: country.dial,
      }))
      .filter((country) => !!country.dial)
      .sort((a, b) => a.name.localeCompare(b.name))
  ), [])

  return {
    countries,
  }
}

export default useJsonData
