import { gql } from '@apollo/client'

const query = gql`
  query countriesList {
    countries {
      nodes {
        id: iso31661
        key: iso31661
        iso31661
        name
      }
    }
  }
`

export default query
