import { type ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'i18n/client'
import useCountrySelect from '../useCountrySelect'
import Styles from './SearchInput.module.sass'

type UseDataReturnValue = ReturnType<typeof useCountrySelect>

function SearchInput({
  setSearchTerm,
}: { setSearchTerm: UseDataReturnValue['setSearchTerm'] }) {
  const { t } = useTranslation()

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [setSearchTerm])

  return (
    <input
      placeholder={t('modal.countrySelector.searchPlaceholder', 'Search...')}
      className={Styles.input}
      onChange={onChange}
    />
  )
}

export default SearchInput
